import { callExternalApi } from "./external-api.service";

const apiServerUrl = 'http://localhost:6060';

export const getGyms = async(page) =>{
  const response = await fetch(`${apiServerUrl}/api/gym/all/paginated/`+page);
  return response
};

export const getGym = async (id, accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/gym/`+id+`/admin`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const onboardGym = async (id, accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/gym/`+id+`/admin/onboard`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const gymUpdateStripeData = async (id, accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/gym/`+id+`/admin/stripe/update`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};